<template>
    <div class="relative z-0">
        <div class="text-2xl font-bold text-center text-secondary mb-8">{{ data.Name }}</div>
        <div ref="map" class="w-full h-[400px]" v-if="data"></div>
    </div>
</template>

<script>
export default {
    name: "OpenStreetMap",
    props: {
        data: {
            type: Object,
            default: null,
            required: true,
        },
    },
    methods: {
        createMap() {
            const mymap = L.map(this.$refs.map, {
                scrollWheelZoom: false
            });
            if (this.data.Points.length) {
                mymap.setView([this.data.Points[0].Lat, this.data.Points[0].Lon], this.data.Zoom);
            } else {
                mymap.setView([52.1633791, 22.2953472], this.data.Zoom);
            }
            L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {}).addTo(mymap);
            this.data.Points.forEach(el => {
                let newMarker = new L.marker([el.Lat, el.Lon]).addTo(mymap);
                let popupText =
                    `<div>
                        <div class="font-bold text-primary mb-2">${el.Name}</div>
                        <div class="prose prose-map text-sm">${el.Content.replace(/\s\s+/, '')}</div>
                    </div>`
                ;
                newMarker.bindPopup(popupText);
            });
        },
    },
    mounted() {
        this.createMap();
    },
};
</script>

<style>
</style>