<template>
    <div>
        <button type="button" class="fixed z-40 w-12 h-12 rounded-full bg-secondary text-white bottom-4 right-4 flex items-center justify-center border-none outline-none focus:ring focus:ring-secondary/30 select-none" @click="visible = true">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" />
            </svg>
        </button>
        <div class="flex flex-col justify-between fixed top-0 w-screen h-screen overflow-auto bg-primary transition-all z-50 py-8" :class="visible ? 'left-0' : '-left-screen'">
            <ul class="block text-center">
                <li class="block" v-for="(item, index) in data" :key="item.Id" @click="toggleSub(index)">
                    <span class="block text-3xl px-8 text-white py-2" v-if="Array.isArray(item.Sub)">{{ item.Name }}</span>
                    <a v-else class="block text-3xl px-8 text-white py-2" :href="item.Link" :target="item.IsNewWindow == 1 ? '_blank' : '_self'">{{ item.Name }}</a>
                    <ul v-if="Array.isArray(item.Sub)" class="block bg-white bg-opacity-5 overflow-hidden transition-all duration-500" :class="index === sub ? 'max-h-screen' : 'max-h-0'">
                        <li v-for="sub in item.Sub" :key="sub.Id" class="block">
                            <a class="block text-3xl px-8 py-2 text-white" :href="sub.Link" :target="sub.IsNewWindow == 1 ? '_blank' : '_self'">{{ sub.Name }}</a>
                        </li>
                    </ul>
                </li>
            </ul>
            <div></div>
        </div>
        <button type="button" class="fixed z-50 w-12 h-12 rounded-full bg-red-600 text-white bottom-4 right-4 flex items-center justify-center border-none outline-none focus:ring focus:ring-red-600/30 select-none" :class="visible ? 'right-4 left-auto' : '-left-full'" @click="visible = false">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
        </button>
    </div>
</template>

<script>
    export default {
        name: 'MobileMenu',
        props: ['data'],
        data() {
            return {
                url: window.location.protocol + '//' + window.location.host,
                visible: false,
                sub: -1,
            }
        },
        methods: {
            toggleSub(index) {
                if (this.sub == index) {
                    this.sub = -1
                } else {
                    this.sub = index
                }
            },
        },
    }
</script>

<style></style>
