import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueTheMask from "vue-the-mask"
import Vuelidate from 'vuelidate'
import VueCookies from 'vue-cookies'

Vue.use(VueAxios, axios)
Vue.use(VueTheMask)
Vue.use(Vuelidate)
Vue.use(VueCookies)

// components
import Cookies from './components/Cookies'
import MobileMenu from './components/MobileMenu'
import OpenStreetMap from './components/OpenStreetMap'

const app = new Vue({
    el: '#app',
    components: {
        Cookies,
        MobileMenu,
        OpenStreetMap
    }
});